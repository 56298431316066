// intentionally written in js (CommonJS) to allow it to be imported anywhere without transpiling

const validLocaleValue = (val) => {
  const pattern = new RegExp(/[a-z]{2}-[a-z]{2}/i);
  return pattern.test(val);
};

// possible values are `true` or `false` (straightforward toggle for the whole site)
// or comma separated locales, e.g. `en-ie,en-fr` etc.
// invalid values or not setting env var will be treated as false
const parseStorefrontEnvVar = () => {
  const storefrontEnvVar = process.env.NEXT_PUBLIC_DISABLE_STOREFRONT;

  if (!storefrontEnvVar || storefrontEnvVar === 'false') {
    return false;
  }

  if (storefrontEnvVar === 'true') {
    return true;
  }

  const localesToTarget = storefrontEnvVar
    .split(',')
    .map((val) => val.trim())
    .filter((val) => !!val);
  if (localesToTarget.length > 0 && localesToTarget.every((val) => validLocaleValue(val))) {
    return localesToTarget;
  }

  return false;
};

module.exports = parseStorefrontEnvVar;
