import { i18n } from 'next-i18next.config';

import parseStorefrontEnvVar from '@lib/parse-storefront-env-var';

export function isStorefrontDisabled(locale?: string) {
  const disableStorefront = parseStorefrontEnvVar();

  if (typeof disableStorefront === 'boolean') {
    return disableStorefront;
  }

  // check if storefront disabled for specific locales
  return disableStorefront.includes(locale || i18n.defaultLocale);
}

export function isLife360Enabled() {
  return [process.env.NEXT_PUBLIC_IS_LIFE360, process.env.NEXT_PUBLIC_IS_LIFE360_INTL].some((val) => val === 'true');
}

export function isLife360IntlSite() {
  return process.env.NEXT_PUBLIC_IS_LIFE360_INTL === 'true';
}
